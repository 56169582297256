import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import styled from 'styled-components'
import ContentContainer from '@/components/UI/ContentContainer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'

const TextWrapper = styled.div`
  text-align: center;
  margin-inline: auto;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.semiTitle};
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    font-size: ${({ theme }) => theme.fontSize.s25};
  }
`

const Text = styled.div`
  line-height: 20px;
  max-width: 600px;
  margin-inline: auto;

  p {
    margin-bottom: 20px;
  }
`

const CompanyPageTrustIsFoundation: React.FC = () => {
  const {
    allContentfulWeAreFortressCompanyPage: { nodes },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "WhatBg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulWeAreFortressCompanyPage {
        nodes {
          title
          text {
            raw
          }
        }
      }
    }
  `)

  const { title, text } = nodes[1]

  const richText = renderRichText(text, richTextOptions)

  return (
    <BackgroundImageWrapper image={backgroundImage.childImageSharp.fluid}>
      <ContentContainer>
        <TextWrapper>
          <Title
            data-sal="slide-down"
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="easeOutQuad"
          >
            {title}
          </Title>
          <Text
            data-sal="fade-in"
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="easeOutQuad"
          >
            {richText}
          </Text>
        </TextWrapper>
      </ContentContainer>
    </BackgroundImageWrapper>
  )
}

export default CompanyPageTrustIsFoundation
