import * as React from 'react'
import Layout from '@/components/layout'
import { CompanyPageHeader } from '@/components/CompanyPage/CompanyPageHeader'
import { CompanyPageWeAreFortress } from '@/components/CompanyPage/CompanyPageWeAreFortress'
import { CompanyPageOurTeam } from '@/components/CompanyPage/CompanyPageOurTeam'
import { PartnersSection } from '@/components/PartnersSection'
import CompanyPageContactForm from '@/components/CompanyPage/CompanyPageContactForm'
import { Footer } from '@/components/Footer'
import { graphql, useStaticQuery } from 'gatsby'
import CompanyPageTrustIsFoundation from '@/components/CompanyPage/CompanyPageTrustIsFoundation'

const Company: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          companyPage
        }
      }
    }
  `)

  const { companyPage } = nodes[0]
  return (
    <Layout title={companyPage}>
      <CompanyPageHeader />
      <CompanyPageWeAreFortress />
      <CompanyPageTrustIsFoundation />
      <CompanyPageOurTeam />
      <PartnersSection isBackgroundWhite />
      <CompanyPageContactForm />
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//
//     allContentfulSubPagesTitles {
//       nodes {
//         contactUsPage
//         platformPage
//         privacyPolicyPage
//         termsOfUsePage
//         itManagerPage
//         contentful_id
//         itVendorPage
//         smbOwnerPage
//         sdf
//       }
//     }
//   }
// `

export default Company
