import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import theme from '@/utils/theme'
import Title from '@/components/UI/Title'
import ContentContainer from '@/components/UI/ContentContainer'
import { useWindowSize } from '@/hooks'

const Wrapper = styled(ContentContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    height: 400px;
  }
`

const StyledTitle = styled(Title)`
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    margin-bottom: 150px;
  }

  max-width: 920px;
`

export const CompanyPageHeader: React.FC = () => {
  const {
    allContentfulCompanyHeaderSection: { nodes },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "companyHeader.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulCompanyHeaderSection {
        nodes {
          title
        }
      }
    }
  `)
  const { width } = useWindowSize()
  const { title } = nodes[0]

  return (
    <BackgroundImageWrapper
      styles={
        width < 900
          ? { backgroundSize: '142vw', backgroundPosition: 'bottom' }
          : { backgroundSize: 'contain', backgroundPosition: 'bottom' }
      }
      image={backgroundImage.childImageSharp.fluid}
      backgroundColor={theme.colors.lightGray}
    >
      <Wrapper>
        <StyledTitle
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="easeOutQuad"
        >
          {title}
        </StyledTitle>
      </Wrapper>
    </BackgroundImageWrapper>
  )
}
