import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import { ImageGraphqlProps } from '@/utils/types'
import QuotePlaceholder from '@/assets/quotePlaceholder.svg'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import theme from '@/utils/theme'
import ContentContainer from '@/components/UI/ContentContainer'

interface GraphqlProps {
  backgroundImage: any
  allContentfulOurTeamSection: {
    nodes: {
      title: string
      people: {
        text: RenderRichTextData<never>
        name: string
        jobTitle: string
        image: ImageGraphqlProps
        socialsData: { icon: ImageGraphqlProps; url: string }
      }[]
    }[]
  }
}

export const CompanyPageOurTeam: React.FC = () => {
  const {
    allContentfulOurTeamSection: { nodes },
    backgroundImage,
  }: GraphqlProps = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "companyOurTeamBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulOurTeamSection {
        nodes {
          title
          people {
            text {
              raw
            }
            name
            jobTitle

            image {
              file {
                url
              }
            }
            socialsData {
              icon {
                file {
                  url
                }
              }
              url
            }
          }
        }
      }
    }
  `)
  const { title, people } = nodes[0]
  const [y, setY] = useState<number | undefined>(0)

  useEffect(() => {
    const elmnt = document.querySelector('.read-more')
    const y = elmnt?.scrollHeight
    setY(y)
  }, [])

  const RenderText = ({ children }: any) => children

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => (
        <RenderText>{children}</RenderText>
      ),
    },
  }

  const peopleElements = people.map((item, key: number) => {
    const richText = renderRichText(item.text, options)
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
      setIsOpen(!isOpen)
    }

    return (
      <Section
        data-sal="slide-down"
        data-sal-duration="1000"
        data-sal-delay={`${200 * key}`}
        data-sal-easing="easeOutQuad"
      >
        <PersonImage image={item.image?.file.url || QuotePlaceholder} />
        <Name>{item.name}</Name>
        <JobTitle>{item.jobTitle}</JobTitle>
        <Description
          className={'read-more'}
          isOpen={isOpen}
          height={isOpen ? `${y && y + 200}px` : '0px'}
        >
          {richText}
        </Description>
        <button onClick={toggle}>
          {isOpen ? 'Read less' : 'Read more'}
        </button>
        <br />
        <a href={item.socialsData.url}>
          <img src={item.socialsData.icon.file.url} alt="icon" />
        </a>
      </Section>
    )
  })

  return (
    <BackgroundImageWrapper
      styles={{
        backgroundSize: 'initial',
        backgroundPositionY: 'bottom',
        backgroundPositionX: 'left',
      }}
      image={backgroundImage.childImageSharp.fluid}
      backgroundColor={theme.colors.lightGray}
    >
      <ContentContainer>
        <Title
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="easeOutQuad"
        >
          {title}
        </Title>
        <Cards>{peopleElements}</Cards>
      </ContentContainer>
    </BackgroundImageWrapper>
  )
}

const Title = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.s25};
  margin-bottom: 20px;
`
const Cards = styled.div`
  justify-content: center;
  flex-flow: wrap;
  display: flex;
`

const Section = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-inline: auto;
  width: 314px;
  @media (min-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    margin-inline: 20px;
  }
  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    margin-inline: 50px;
  }

  button {
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.blue};
  }
`
const PersonImage = styled.div<{ image: string }>`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
  background-image: url('${({ image }) => image}');
  background-size: cover;
  background-position: center;
`

const Name = styled.p`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.s22};
`

const JobTitle = styled.p`
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.fontSize.s20};
`

const Description = styled.div<{ height: string; isOpen: boolean }>`
  max-height: ${({ height }) => height};
  font-size: ${({ theme }) => theme.fontSize.text};
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  margin: 0 auto;
  margin-top: 10px;
`
