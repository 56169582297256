import React from 'react'
import styled from 'styled-components'
import ContactForm from '@/components/ContactForm/ContactForm'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import ContentContainer from '@/components/UI/ContentContainer'

const Wrapper = styled(ContentContainer)`
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Title = styled.div`
  display: inline-block;
  text-align: center;
  max-width: 510px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  margin-bottom: 40px;
`
interface GraphqlProps {
  backgroundImage: any
  allContentfulCompanyContactFormSection: {
    nodes: {
      title: string
    }[]
  }
}

const CompanyPageContactForm: React.FC = () => {
  const {
    allContentfulCompanyContactFormSection: { nodes },
    backgroundImage,
  }: GraphqlProps = useStaticQuery(graphql`
    {
      backgroundImage: file(
        relativePath: { eq: "companyContactFormBg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulCompanyContactFormSection {
        nodes {
          title
        }
      }
    }
  `)

  const { title } = nodes[0]

  return (
    <BackgroundImageWrapper image={backgroundImage.childImageSharp.fluid}>
      <Wrapper>
        <Title
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          {title}
        </Title>
        <ContactForm primary />
      </Wrapper>
    </BackgroundImageWrapper>
  )
}

export default CompanyPageContactForm
